/***
 *
 *   USERS
 *   Enables an admin to manage the users in their application
 *
 **********/

import { useMutation, useQuery } from '@tanstack/react-query';
import { ENDPOINTS, apiService } from 'api';
import Bitbucket from 'assets/Bitbucket.svg';
import Github from 'assets/Github.svg';
import Gitlab from 'assets/Gitlab.svg';
import { useTeam } from 'components/hooks/useTeam';
import {
  Animate,
  AuthContext,
  BlankSlateMessage,
  Button,
  Card,
  Loader,
  Table,
  TitleRow,
  Tooltip,
  ViewContext,
  useInvite,
  Dropdown,
} from 'components/lib';
import { PortalNav } from 'components/nav/sub/portal';
import { useContext, useEffect, useState } from 'react';
import { events, mixpanelService } from '../../app/analytics';
import { EditableTeamTitle } from './editableTeamTitle';
import { ManageTeamDropdown } from './manageTeamDropdown';
import { useUser } from 'components/hooks/useUser';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { isEmpty } from 'lodash';
import { useOrganization } from '../../components/hooks/useOrganization';
import { RemoveUserModal } from './removeUserModal';
import { useParams } from 'react-router-dom';
import { useNavigate } from '../../components/lib';
import { getRelativeTime, humanRelativeTime } from '../../utils';

const gitProvidersConnections = {
  github: {
    icon: Github,
  },
  bitbucket: {
    icon: Bitbucket,
  },
  gitlab: {
    icon: Gitlab,
  },
};

export const getTeamUser = async (id) => {
  try {
    const data = await apiService.callApi({
      endpoint: `${ENDPOINTS.TEAMS}/${id}/${ENDPOINTS.USERS}`,
      errorEvent: events.GET_TEAM_USERS_ERROR,
    });
    return data;
  } catch (err) {
    return err;
  }
};

export const Users = () => {
  const navigate = useNavigate();
  const context = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const { teamId } = useParams();
  const [users, setUsers] = useState([]);
  const { organization } = useOrganization();
  const { team, addTeam, refetchTeam } = useTeam(teamId);
  const { user } = useUser();
  const { inviteMembersToTeam } = useInvite({ teamId: team?.id });
  const [userIdToRemove, setUserIdToRemove] = useState(undefined);
  const userTeamId = user?.teams?.[0]?.team_id;
  const isOrgOwner = user?.organization_permission === 'owner';
  const isTeamOwner = user?.permission === 'owner' && userTeamId === teamId;
  const showEnv =
    (isOrgOwner || isTeamOwner) && !isEmpty(organization?.baseUrlOptions);

  useEffect(() => {
    if (isEmpty(teamId) && userTeamId) {
      navigate(`/team/${userTeamId}/users`);
    }
  }, [teamId, team]);

  const {
    data: teamUsers,
    isPending,
    refetch: refetchTeamUsers,
  } = useQuery({
    queryKey: ['teamUsers', teamId],
    queryFn: () => getTeamUser(teamId),
  });

  const inviteCallback = (form, res) => {
    // add the invited user to the
    if (res.length) {
      const state = [...users];

      res.forEach((invite) => {
        if (state.some((x) => x.email === invite.email)) {
          context.notification.show(
            `User ${invite.email} already invited`,
            'error',
            true,
            'toast',
            'alert-triangle',
          );
          return;
        }
        state.push({
          id: invite.id,
          name: invite.name,
          email: invite.email,
          date_created: invite.date_sent,
          role: invite.permission || 'user',
          status: 'Invited',
        });
      });

      context.notification.show(res.message, 'success', true, 'toast', 'heart');

      setUsers(state);
    }
  };

  const removeUser = (user) => {
    mixpanelService.trackEvent(events.REMOVE_MEMBER_FROM_TEAM_CLICK, {
      team_id: team.id,
    });
    const encodedUserId = encodeURIComponent(user.id);
    context.modal.show(
      {
        title: 'Remove User',
        form: {},
        buttonText: 'Remove User',
        text: `Are you sure you want to remove ${user.name}?`,
        url: `/platform/v2/teams/${team.id}/users/${encodedUserId}`,
        method: 'DELETE',
        destructive: true,
      },
      () => {
        context.notification.show(user.name + ' was deleted', 'success', true);
        onUserRemoved(user.id);
      },
    );
  };

  const onUserRemoved = (removedUserId) => {
    refetchTeamUsers();
    if (removedUserId === authContext.user.email) {
      refetchTeam();
    }
  };

  const { mutateAsync: updateUser } = useMutation({
    mutationFn: async (data) => {
      return apiService.callApi({
        endpoint: `${ENDPOINTS.USERS}`,
        method: 'patch',
        data,
      });
    },
    onSuccess: () => refetchTeamUsers(),
  });

  const TeamPermission = ({ user }) => {
    let permission;
    if (!team) return;
    if (user.permission === 'owner') {
      permission = 'Admin';
    } else permission = 'Member';

    if (!(isTeamOwner || isOrgOwner)) {
      return <div className="text-brand1-300">{permission}</div>;
    }

    return (
      <div>
        <Dropdown>
          <>
            <Dropdown.Trigger>
              <div className="flex items-center gap-1 cursor-pointer text-brand1-300">
                <span>{permission}</span>
                <ChevronDownIcon />
              </div>
            </Dropdown.Trigger>
            <Dropdown.Menu>
              <>
                <Dropdown.MenuItem
                  onSelect={() =>
                    updateUser({
                      attributes: { permission: 'user' },
                      user_id: user.id,
                    })
                  }
                >
                  Member
                </Dropdown.MenuItem>
                <Dropdown.MenuItem
                  onSelect={() =>
                    updateUser({
                      attributes: { permission: 'owner' },
                      user_id: user.id,
                    })
                  }
                >
                  Admin
                </Dropdown.MenuItem>
              </>
            </Dropdown.Menu>
          </>
        </Dropdown>
      </div>
    );
  };

  const Environment = ({ user }) => {
    const options = organization?.baseUrlOptions;
    if (isEmpty(options)) return;
    const currentOption = options.find(
      (option) => option.baseUrl === user?.baseUrl,
    );

    return (
      <div>
        <Dropdown>
          <>
            <Dropdown.Trigger>
              <div className="flex items-center gap-1 cursor-pointer text-brand1-300">
                <span>{currentOption?.name ?? user?.baseUrl}</span>
                <ChevronDownIcon />
              </div>
            </Dropdown.Trigger>
            <Dropdown.Menu>
              <>
                {options.map((option) => (
                  <Dropdown.MenuItem
                    key={`Environment-${option.name}`}
                    onSelect={() =>
                      updateUser({
                        attributes: { baseUrl: option.baseUrl },
                        user_id: user.id,
                      })
                    }
                  >
                    <div>
                      <div className="font-semibold">{option.name}</div>
                      <div className="text-gray-500">{option.baseUrl}</div>
                    </div>
                  </Dropdown.MenuItem>
                ))}
              </>
            </Dropdown.Menu>
          </>
        </Dropdown>
      </div>
    );
  };

  useEffect(() => {
    // format the user list
    let list = [];
    if (teamUsers?.error) {
      context.notification.show(teamUsers?.error, 'error', true);
      return;
    }

    if (teamUsers?.users?.length) {
      list = teamUsers.users.map((x) => {
        const prAgentConnectedProviders = ['github', 'gitlab', 'bitbucket'];

        const providers = [];

        prAgentConnectedProviders.forEach((provider) => {
          const userIdField = `${provider}_user_id`;
          const userNameField = `${provider}_user_name`;
          if (x[userIdField] && x[userNameField]) {
            providers.push(
              <Tooltip key={provider} content={`Enabled in ${provider}`}>
                <img
                  className={'w-6'}
                  alt="git_provider"
                  src={gitProvidersConnections[provider].icon}
                />
              </Tooltip>,
            );
          }
        });

        let action;

        if (isTeamOwner || isOrgOwner || user.id === x.id) {
          if ((isTeamOwner || isOrgOwner) && organization?.invite_only) {
            action = [
              {
                action: (x) => setUserIdToRemove(x.id),
                icon: 'trash',
              },
            ];
          } else {
            action = [
              {
                action: removeUser,
                icon: 'trash',
              },
            ];
          }
        }

        const relativeLastLogin = getRelativeTime(x.last_login);

        return {
          id: x.id,
          avatar: x.avatar,
          name: x.name,
          email: x.email,
          date_created: x.date_created,
          role: <TeamPermission user={x} />,
          status: x.verified ? 'Verified' : 'Registered',
          'qodo Merge Pro': providers.length ? (
            <div className="flex gap-2">{providers}</div>
          ) : (
            'Not Connected'
          ),
          environment: <Environment user={x} />,
          last_login: relativeLastLogin,
          actions: {
            custom: action,
          },
        };
      });
    }

    if (teamUsers?.invites?.length) {
      teamUsers.invites.forEach((x) => {
        list.push({
          id: x.id,
          avatar: x.avatar,
          name: x.name,
          email: x.email,
          date_created: x.date_sent,
          role: 'Member',
          status: 'Invited',
          'qodo Merge Pro': '',
          environment: '',
          last_login: '',
          actions: { custom: [] },
        });
      });
    }

    setUsers(list);
  }, [teamUsers]);

  // TODO: Un-comment once we add re-send invite and delete invite
  // attach the per row actions for invites
  // if (users.length) {
  //   users.forEach((u) => {
  //     if (u.status === 'Invited') {
  //       u.actions = {
  //         invite: resendInvite,
  //         delete: deleteInvite,
  //       };
  //     }
  //   });
  // }

  return (
    <div className="flex flex-col gap-4">
      <PortalNav />
      {isPending && <Loader center />}
      {!isPending && (
        <Animate>
          <>
            {(team || isOrgOwner) && (
              <>
                <TitleRow
                  className={'my-5'}
                  title={team ? <EditableTeamTitle teamId={team?.id} /> : null}
                >
                  <div className="flex gap-2">
                    {(isTeamOwner || isOrgOwner) && team && (
                      <ManageTeamDropdown
                        teamId={teamId}
                        refetchTeamUsers={refetchTeamUsers}
                      />
                    )}
                    {team && (isTeamOwner || isOrgOwner) && (
                      <Button
                        small
                        text="Invite Members"
                        action={() => inviteMembersToTeam(inviteCallback)}
                      />
                    )}
                  </div>
                </TitleRow>
                {team && (
                  <Card>
                    <Table
                      search={false}
                      className="restrict-width"
                      data={users}
                      loading={isPending}
                      show={[
                        'name',
                        'email',
                        'role',
                        showEnv && 'environment',
                        'status',
                        'qodo Merge Pro',
                        'last_login',
                      ]}
                      badge={{
                        col: 'status',
                        color: 'green',
                        condition: [
                          { value: 'verified', color: 'green' },
                          { value: 'registered', color: 'green' },
                          { value: 'invited', color: 'purple' },
                        ],
                      }}
                    />
                  </Card>
                )}
              </>
            )}
            {!team && (
              <div>
                <BlankSlateMessage
                  title="Create a new Team"
                  text={`You haven't created any team yet. Would you like to create one now?`}
                  buttonText="Create Team"
                  action={() => {
                    mixpanelService.trackEvent(events.CREATE_TEAM_CLICK);
                    addTeam({
                      callback: ({ team }) => {
                        navigate(`/team/${team.id}/users`);
                      },
                    });
                  }}
                />
              </div>
            )}
          </>
        </Animate>
      )}
      {userIdToRemove && (
        <RemoveUserModal
          userId={userIdToRemove}
          teamId={team?.id}
          organizationId={organization?.id}
          closeModal={() => setUserIdToRemove(undefined)}
          onSuccess={() => onUserRemoved(userIdToRemove)}
        />
      )}
    </div>
  );
};
