import { Dropdown } from '../../../components/dropdown/dropdown';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import React from 'react';

export const OrgPermission = ({ organization, user, updateUser }) => {
  let permission;
  if (user.organization_permission === 'owner') {
    permission =
      organization?.billing_admin === user.id ? 'Billing-Admin' : 'Admin';
  } else permission = 'Member';

  if (permission === 'Billing-Admin') {
    return <div className="text-brand1-300">{permission}</div>;
  }

  const handlePermissionChange = async (newPermission) => {
    await updateUser({
      attributes: { organization_permission: newPermission },
      user_id: user.id,
    });
  };

  return (
    <div>
      <Dropdown>
        <Dropdown.Trigger>
          <div className="flex items-center gap-1 cursor-pointer text-brand1-300">
            <span>{permission}</span>
            <ChevronDownIcon />
          </div>
        </Dropdown.Trigger>
        <Dropdown.Menu>
          <Dropdown.MenuItem onSelect={() => handlePermissionChange('user')}>
            Member
          </Dropdown.MenuItem>
          <Dropdown.MenuItem onSelect={() => handlePermissionChange('owner')}>
            Admin
          </Dropdown.MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
