import { Dropdown } from '../../../components/dropdown/dropdown';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import React from 'react';
import { TIER_LEVELS } from '../../../constants';
import { Tooltip } from '../../../components/tooltip/tooltip';
import { Icon } from '../../../components/icon/icon';

const TeamPermission = ({ user, team, updateUser }) => {
  if (!team) return null;
  const permission = user.permission === 'owner' ? 'Admin' : 'Member';

  const handlePermissionChange = async (newPermission) => {
    await updateUser({
      attributes: { permission: newPermission },
      user_id: user.id,
    });
  };

  return (
    <div>
      <Dropdown>
        <Dropdown.Trigger>
          <div className="flex items-center gap-1 cursor-pointer text-brand1-300">
            <span>{permission}</span>
            <ChevronDownIcon />
          </div>
        </Dropdown.Trigger>
        <Dropdown.Menu>
          <Dropdown.MenuItem onSelect={() => handlePermissionChange('user')}>
            Member
          </Dropdown.MenuItem>
          <Dropdown.MenuItem onSelect={() => handlePermissionChange('owner')}>
            Admin
          </Dropdown.MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export const getTeamLabel = (team, user, updateUser) => {
  if (!team) return null;
  return (
    <div className={'flex items-center gap-1'}>
      <TeamPermission user={user} team={team} updateUser={updateUser} /> at{' '}
      <span>{team.name}</span>
      {team.tier_level === TIER_LEVELS.PAID && (
        <Tooltip
          side="top"
          content={<p className="leading-6 text-xs">Independent team</p>}
        >
          <Icon asChild image="info" color="#AEA1F1" size={14} />
        </Tooltip>
      )}
    </div>
  );
};
