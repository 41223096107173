import React, { useRef, useCallback, useEffect } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { Card } from 'components/lib';
import Style from '../../../components/table/table.tailwind';
import { createColumns } from './tableColumns';

const ESTIMATESIZE = 66;

export const MembersTable = ({
  data,
  organization,
  isFetching,
  fetchNextPage,
  updateUser,
  removeUser,
  teams,
  hasMoreData,
  searchParam,
}) => {
  const tableContainerRef = useRef(null);

  const flatData = React.useMemo(() => {
    if (!data?.pages || data.pages.length === 0) {
      return [];
    }
    return data.pages.flatMap((page) => [
      ...(page?.users ?? []),
      ...(page?.invites ?? []),
    ]);
  }, [data]);

  const columns = React.useMemo(
    () =>
      createColumns({
        organization,
        updateUser,
        removeUser,
        teams,
      }),
    [organization, updateUser, removeUser, teams],
  );

  const table = useReactTable({
    data: flatData,
    columns: columns,
    defaultColumn: {
      minSize: 60,
      maxSize: 3000,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: 'includesString', // built-in filter function
    state: {
      globalFilter: searchParam,
    },
    manualSorting: true,
    debugColumns: true,
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => ESTIMATESIZE,
    getScrollElement: () => tableContainerRef.current,
    // Measure element heights dynamically, with special handling for Firefox
    // Firefox has issues with dynamic measurement, so we fall back to estimated sizes
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 7,
  });

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

        if (
          scrollHeight - scrollTop - clientHeight < 500 &&
          !isFetching &&
          hasMoreData
        ) {
          console.log('fetchNextPage');
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, hasMoreData],
  );

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  return (
    <Card>
      <div
        className="containerOfTable"
        onScroll={(e) => fetchMoreOnBottomReached(e.target)}
        ref={tableContainerRef}
        style={{
          overflow: 'auto',
          overflowAnchor: 'none',
          position: 'relative',
          height: '500px',
          width: '100%',
        }}
      >
        <table style={{ display: 'grid' }}>
          <thead
            style={{
              display: 'flex',
              position: 'sticky',
              top: 0,
              opacity: 1,
              zIndex: 2,
            }}
            className={Style.thead}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className={Style.thead}
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                {headerGroup.headers.map((header, index) => {
                  return (
                    <th
                      key={header.id}
                      style={{
                        display: 'flex',
                        width: '244px',
                        textAlign: 'left',
                      }}
                    >
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {rows.length === 0 ? (
            <Card center>No results found. Try adjusting your search.</Card>
          ) : (
            <tbody
              style={{
                display: 'grid',
                height: `${rowVirtualizer.getTotalSize()}px`,
                position: 'relative',
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const row = rows[virtualRow.index];

                return (
                  <tr
                    data-index={virtualRow.index}
                    ref={(node) => rowVirtualizer.measureElement(node)}
                    key={row.id}
                    className={Style.row}
                    style={{
                      display: 'flex',
                      position: 'absolute',
                      transform: `translateY(${virtualRow.start}px)`,
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    {row.getVisibleCells().map((cell, index) => {
                      return (
                        <td
                          key={cell.id}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            width: '244px',
                            marginRight: '10px',
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </Card>
  );
};
